import React from "react"
import PropTypes from "prop-types"
import { FaUser, FaPhone, FaEnvelope, FaBuilding, FaMapMarkerAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import {onChange, submitInformation} from "../../redux/group/groupAction"
import CountriesInput from "./information/countriesInput"
import PhoneInput from "../../components/form/phoneInput"
import AlertCategory from "./alertCategory/alertCategory"
import {isDefined} from "../../utils/data"

const Information = ({prevTab, nextTab}) => {
    const dispatch      = useDispatch()
    const {information, onEdit} = useSelector(({groupReducer}) => groupReducer)

    const disabledInput = isDefined(onEdit) ? onEdit : false

    const onSubmit = (e = null) => {
        e.preventDefault()
        const form = e.currentTarget

        if (form.checkValidity() === false) {
            e.stopPropagation()
        }

      dispatch(submitInformation(onEdit, information, nextTab))
    }

    return (
        <div className={"content about"}>
            <p className={"title"}>
                Group Coordinator Profile
            </p>
            <AlertCategory/>

            <p className={"important-infos"}>Group Registration is intended for a minimum of 2 delegates and up.<br/>Please start by entering your group coordinator profile here</p>
            <p>Group & Coordinator information</p>

            <form onSubmit={onSubmit} className={"needs-validation"} noValidate>
                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-4 col-lg-3 input-group"}>
                            <label><FaUser/></label>
                            <select className="form-control" name={"group_gender"} value={information.group_gender.value} onChange={e => dispatch(onChange(e))} required>
                                <option value="" disabled selected>Title</option>
                                <option>Mr</option>
                                <option>Ms</option>
                                <option>Mx</option>
                                <option>Dr</option>
                                <option>Prof</option>
                            </select>
                            {information.group_gender.error !== "" ?
                                <div className="invalid-tooltip">{information.group_gender.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-5 input-group"}>
                            <label><FaUser/></label>

                            <input type="text" className="form-control" placeholder="Coordinator First Name" name={"group_firstname"} value={information.group_firstname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                        {information.group_firstname.error !== "" ?
                            <div className="invalid-tooltip">{information.group_firstname.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaUser/></label>
                            <input type="text" className="form-control" placeholder="Coordinator Last Name" name={"group_lastname"} value={information.group_lastname.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                        {information.group_lastname.error !== "" ?
                            <div className="invalid-tooltip">{information.group_lastname.error}</div>
                        : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaEnvelope/></label>
                           <input type="email" className="form-control" placeholder="Group coordinator Email" name={"group_mail"} value={information.group_mail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                            {information.group_mail.error !== "" ?
                                <div className="invalid-tooltip">{information.group_mail.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaEnvelope/></label>
                            <input type="email" className="form-control" placeholder="Confirm Email" name={"confirmEmail"} value={information.confirmEmail.value} onChange={e => dispatch(onChange(e))} disabled={disabledInput} required/>
                            {information.confirmEmail.error !== "" ?
                                <div className="invalid-tooltip">{information.confirmEmail.error}</div>
                            : ""}
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-row">
                        <div className={"col-12 col-md-12 input-group"}>
                            <label><FaBuilding/></label>
                            <input type="text" className="form-control" placeholder="Group Name Society/Organisation/Institution" name={"group_society_name"} value={information.group_society_name.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.group_society_name.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_name.error}</div>
                                : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Group Invoice Address" name={"group_society_adress"} value={information.group_society_adress.value} onChange={e => dispatch(onChange(e))} required/>
                        {information.group_society_adress.error !== "" ?
                            <div className="invalid-tooltip">{information.group_society_adress.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Additional address" name={"group_society_adress2"} value={information.group_society_adress2.value} onChange={e => dispatch(onChange(e))} required={information.group_society_adress2.required}/>
                            {information.group_society_adress2.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_adress2.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="Postal code" name={"group_society_cp"} value={information.group_society_cp.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.group_society_cp.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_cp.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                            <input type="text" className="form-control" placeholder="City" name={"group_society_city"} value={information.group_society_city.value} onChange={e => dispatch(onChange(e))} required/>
                            {information.group_society_city.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_city.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-4 col-lg-4 input-group"}>
                            <label><FaMapMarkerAlt/></label>
                                <CountriesInput value={information.group_society_country.value}
                                                onChange={e => dispatch(onChange(e))}
                                                inputCountry={"group_society_country"}
                                                inputPhone={"group_society_phone"}
                                />
                            {information.group_society_country.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_country.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaPhone/></label>
                            <PhoneInput value={information.group_society_phone.value} onChange={e => dispatch(onChange(e))} name={"group_society_phone"}/>
                            {information.group_society_phone.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_phone.error}</div>
                            : ""}
                        </div>
                        <div className={"col-12 col-md-6 input-group"}>
                            <label><FaBuilding/></label>
                            <input type="text" className="form-control" placeholder="Group Invoice vat" name={"group_society_vat"} value={information.group_society_vat.value} onChange={e => dispatch(onChange(e))} />
                            {information.group_society_vat.error !== "" ?
                                <div className="invalid-tooltip">{information.group_society_vat.error}</div>
                                : ""}
                        </div>
                    </div>
                </div>
                <div className={"content-footer"}>
                    <button type={"submit"} className={"sr-btn-next btn btn-info"}>Next</button>
                </div>
            </form>
        </div>
    )
}

Information.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default Information
