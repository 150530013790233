import React from 'react'

import SRModal from "../modal/modal"

const TermsOfSales = ({text}) => (
    <SRModal title={"General Terms and Conditions of Sale"} text={text} className={"addMember"}>
    <div className={"content content-modal"}>
        <h3>General Terms and Conditions of Sale:</h3>
•	Group registration is intended for 10 participants and up<br/>
•	In order to register groups will require a group coordinator<br/>
•	Coordinator will not be considered as participant, unless a full profile is entered <br/>
•	Duplicated participant emails will not be accepted<br/>
•	When last participant is saved and payment is executed no further edits will be allowed <br/>
•	Fees applicable will be according  to the  payment date and confirmation of the full group <br/>
•	Due to the nature of a virtual conference, with all content available to view on demand, our policy is that we cannot accept cancellations or provide refunds.<br/>
</div>
    </SRModal>
)

export default TermsOfSales
