import React from "react"
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { FaInfo, FaIdCard, FaUsers, FaNewspaper } from 'react-icons/fa'

import "./groupTabs.scss"
import {useSelector} from "react-redux";

const GroupTabs = ({information, members, yourData, summary, index, onSelect}) => {
    const {coordinator} = useSelector(({groupReducer}) => groupReducer)
    const { informationTab, membersTab, yourDataTab, summaryTab} = useSelector(({groupTabsReducer}) => groupTabsReducer)
    const tabs = [        
        {
            text: "Coordinator contact",
            icon: <FaInfo/>,
        },
        {
            text: "Group Members",
            icon: <FaUsers/>,
        },
        {
            text: "Summary",
            icon: <FaNewspaper/>,
        }]

    return (
        <Tabs className={"sr-group-tabs"} selectedIndex={index} onSelect={onSelect}>
            <TabList>
                {tabs.map((tab, i) => {
                    return (
                    coordinator === null ?
                        membersTab === false && i > 0 ?
                                ""
                                :
                                    summaryTab === false && i > 1 ?
                                        ""
                                        :
                        <Tab key={i}>
                            <span className={"text"}>{tab.text}</span>
                        </Tab>
                    :
                    <Tab key={i}>
                        <span className={"text"}>{tab.text}</span>
                    </Tab>
                    )})}
                <div className={"moving-tab"} style={{left: index * 33 + "%"}}>
                    <span className={"text"}>{tabs[index].text}</span>
                </div>
            </TabList>

            <TabPanel>
                {information}
            </TabPanel>
            <TabPanel>
                {members}
            </TabPanel>
            <TabPanel>
                {summary}
            </TabPanel>
        </Tabs>
    )
}


GroupTabs.propTypes = {
    information: PropTypes.node.isRequired,
    members:    PropTypes.node.isRequired,
    yourData:   PropTypes.node.isRequired,
    summary:    PropTypes.node.isRequired,
    index:      PropTypes.number.isRequired,
    onSelect:   PropTypes.func.isRequired,
}

export default GroupTabs
