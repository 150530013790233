import React, {useEffect, useState} from "react"
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from "react-redux"

import "./members.scss"

import {validTab} from "../../../redux/tabs/groupTabsAction"
import {editMemberToForm, resetRegistration, startAddMember} from "../../../redux/addMember/addMemberAction"

import SRModal from "../../../components/modal/modal"
import Form from "./addMember/form"
import MemberOrNot from "./addMember/memberOrNot"
import Ticket from "./addMember/ticket"
import Information from "./addMember/information"
import MemberConnection from "./addMember/memberConnection"
import Summary from "./addMember/summary"
import {isDefined} from "../../../utils/data"
import RemoveMemberModal from "./removeMemberModal"


const Members = ({prevTab, nextTab}) => {
    const dispatch = useDispatch()
    const [modalIsOpen, setModalIsOpen] = useState(true)    
    const [pageActive, setPageActive]   = useState(0)
    const {members}                     = useSelector(({groupReducer}) => groupReducer)

    useEffect(() => {
        setPageActive(0)
    }, [members])

    const onClickNext = (e) => {
        e.preventDefault()

        dispatch(validTab("yourDataTab"))
        nextTab()
    }
    
    const onOpenModalEditMember = (member) => {
        onOpenModal()
        setPageActive(2)
        dispatch(editMemberToForm(member))
    }

    const onOpenModalAddMember = () => {
        onOpenModal()
        dispatch(startAddMember())
    }

    const onOpenModal = () => {
        dispatch(resetRegistration())
        setModalIsOpen(true)
    }

    const onCloseModal = () => {
        setModalIsOpen(false)
        setPageActive(0)
    }

    return (
        <div className={"content members"}>
            <div className={"add-member-button"}>
                <SRModal text={"Add a group member"}
                         title={"Add a group member"}
                         className={"addMember"}
                         onClose={() => onCloseModal()}
                         onOpen={() => onOpenModalAddMember()}
                         isOpen={modalIsOpen}>
                    <MemberConnection    pageActive={pageActive} index={0} onNextUnion={() => setPageActive(prev => prev + 1)} onNext={() => setPageActive(prev => prev + 1)}/>
                    <Form pageActive={pageActive} index={1} onNext={() => setPageActive(prev => prev + 1)}/>
                    <Ticket           pageActive={pageActive} index={2} onPrev={() => setPageActive(prev => prev - 1)} onNext={() => setPageActive(prev => prev + 1)}/>
                    <Information         pageActive={pageActive} index={3} onPrev={() => setPageActive(prev => prev - 1)} onNext={() => setPageActive(prev => prev + 1)}/>
                    <Summary        pageActive={pageActive} index={4} onPrev={() => setPageActive(prev => prev - 1)} onCloseModal={onCloseModal}/>
                </SRModal>
            </div>
<p className={"small pt-2"}>Remember to save the group to keep your changes.</p>
            

            <div className={"members-list"}>
                {members.map((member, i) => (
                    <div key={i} className={"row row_member"}>
                        <div className={"col-md-12 col-lg-4"}>
                            {member.registered.registered_firstname + " " + member.registered.registered_lastname}
                        </div>
                        <div className={"col-md-7 col-lg-4"}>
                            {member.registered.registered_mail}
                        </div>
                        <div className={"col-md-2 col-lg-1"}>
                            {(isDefined(member.amount) ? member.amount : (parseFloat(member.registered.registered_invoice))) + " €"}
                        </div>
                        <div className={"col-md-3 col-lg-3"}>
                            <div className={"btn btn-sm btn-success"}>
                                <SRModal text={"Edit"}
                                         title={"Edit a member"}
                                         className={"addMember"}
                                         onClose={() => onCloseModal()}
                                         onOpen={() => onOpenModalEditMember(member)}
                                         isOpen={modalIsOpen}>
                                    <MemberConnection    pageActive={pageActive} index={0} onNextUnion={() => setPageActive(prev => prev + 1)} onNext={() => setPageActive(prev => prev + 1)}/>
                                    <Form pageActive={pageActive} index={1} onNext={() => setPageActive(prev => prev + 1)}/>
                                    <Ticket           pageActive={pageActive} index={2} onPrev={() => setPageActive(prev => prev - 1)} onNext={() => setPageActive(prev => prev + 1)}/>
                                    <Information         pageActive={pageActive} index={3} onPrev={() => setPageActive(prev => prev - 1)} onNext={() => setPageActive(prev => prev + 1)}/>
                                    <Summary        pageActive={pageActive} index={4} onPrev={() => setPageActive(prev => prev - 1)} onCloseModal={onCloseModal}/>
                                </SRModal></div>  <div className={"btn btn-sm btn-warning"}><RemoveMemberModal member={member}/></div>
                        </div>
                    </div>
                ))}
            </div>

            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Previous</button>
                <button className={"sr-btn-next btn btn-info"} onClick={onClickNext}>Next</button>
            </div>
        </div>
    )
}

Members.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default Members
