import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"

import "./summary/summary.scss"
import YourOrder from "./summary/your-order"
import Payment from "./summary/payment"
import {isDefined} from "../../utils/data"
import {onChange} from "../../redux/group/groupAction"
import CheckboxInput from "./welcome/checkboxInput"

const Summary = ({prevTab}) => {
    const dispatch = useDispatch()
    const { members, coordinator } = useSelector(({groupReducer}) => groupReducer)
    const [total, setTotal] = useState(0) 
    const [totaldiscount, setTotaldiscount] = useState(0) 
    const  {information}   = useSelector(({groupReducer}) => groupReducer)

    useEffect(() => {
        setTotal(0)
        members.forEach(member => {
            setTotal(prev => prev + (isDefined(member.amount) ? member.amount : (parseFloat(member.registered.registered_invoice))))
        })
    }, [members])  



    return (
        <div className={"content summary"}>
            <p className={"important-infos"}>Summary and payment</p> 
            <div className={information.group_cgv.value === false || information.group_cgv.value != "1" ? "d-block" : "d-none"}>
                <div className={"row"}>
                    <div className={"col-lg-12"}>
                    <div className={"sr-card summary-block payment"}>
                        <div className={"summary-body mb-2 privacy_text"}>
                        <p>How we use your data</p>
                        <p>The Union will use the personal data from this form only in direct relation to your attendance at the virtual 52nd Union World Conference on Lung Health (such as for registration, programme updates and the mobile app).By participating in the event, delegates give their permission to be photographed or for their image to be used in videos and screenshots recorded by Union personnel and/or attending media. Images and video could be reproduced for subsequent use in the media, webcasts, internal and external promotional purposes, advertising and inclusion on websites and social media.</p>
                        <p>Images are shared publicly with open access for use. We will not share your data for any commercial reason. At the time of registration, before or during the conference, you may specifically request to opt in to share your details with sponsors or by using the electronic business card option agree to your personal data being shared. This usage is in line with The Union’s Privacy Policy. You can change the usage consent at any time by emailing conferences@theunion.org"</p>
                        {!isDefined(coordinator) ? 
                            <CheckboxInput placeholder={" I understand this is how The Union will use my group participants data "}
                            onChange={e => dispatch(onChange(e, true))}
                            name={"group_cgv"}
                            value={information.group_cgv.value}
                            required={true}/>
                            : ""
                        }
                        </div></div>
                    </div>
                </div>
            </div>
            <div className={information.group_cgv.value === false || information.group_cgv.value != "1" ? "d-none" : "d-block"}>
                <div className={"row"}>
                    <div className={"col-lg-6"}>
                        <YourOrder members={members} total={total}/>
                    </div>
                    <div className={"col-lg-6"}>
                        <Payment total={total}/>
                    </div>
                </div>
            </div>
            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Previous</button>
            </div>
        </div>
    )
}

Summary.propTypes = {
    prevTab: PropTypes.func.isRequired,
}

export default Summary
