import React from "react"
import PropTypes from 'prop-types'
import {useDispatch} from "react-redux"

import {validTab} from "../../redux/tabs/tabsAction"

const About = ({nextTab}) => {
    const dispatch = useDispatch()

    const onNext = () => {
        dispatch(validTab("informationTab"))
        nextTab()
    }

    return (
        <div className={"content information"}>
            <p className={"title"}>
                Welcome to<br/>
                <strong>The 52nd Union World Conference on Lung Health</strong><br/>
                registration plateform
            </p>

            <p className={"important-infos"}>Important informations</p>

            <p className={"small"}>
                All orders regardless of their origin are only payable in Euro.<br/>
                Upon receipt of your order and payment, you will receive order confirmation by mail.<br/>
                Nominative countermarks will be send 10 days before event and final tickets will be collected at the entrance.<br/>
                You can book until the last moment on the Internet.<br/>
                A ticket can’t be refunded beyond 20 days before the start date of the event.<br/>
            </p>

            <p className={"small"}>
                When you click on the "Submit" button after the ordering process,<br/>
                you declare that you accept the General Terms and Conditions of Sale.
            </p>


            <div className={"content-footer footer-alone"}>
                <button className={"sr-btn-next btn btn-info"} onClick={() => onNext()}>Next</button>
            </div>
        </div>
    )
}

About.propTypes = {
    nextTab: PropTypes.func.isRequired,
}

export default About
